const actions = {
  PRODUCT_LIST_BEGIN: 'PRODUCT_LIST_BEGIN',
  PRODUCT_LIST_SUCCESS: 'PRODUCT_LIST_SUCCESS',
  PRODUCT_LIST_ERR: 'PRODUCT_LIST_ERR',
  
    productListBegin: () => {
      return {
        type: actions.PRODUCT_LIST_BEGIN,
      };
    },
  
    productListSuccess: (data) => {
      return {
        type: actions.PRODUCT_LIST_SUCCESS,
        data,
      };
    },
  
    productListErr: (err) => {
      return {
        type: actions.PRODUCT_LIST_ERR,
        err,
      };
    },
  
  };
  
  export default actions;
  
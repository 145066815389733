import axios from 'axios';
import Cookies from 'js-cookie';

const API_URL = `${process.env.REACT_APP_MANAGEMENT_API_BASE_URL}user/login`;

const login = (username, password) => {
  return axios
    .post(API_URL, {
      username,
      password,
    })
    .then((response) => {
      if (!response.error) {
        Cookies.set('logedIn', JSON.stringify(response.data));
      }

      return response.data;
    });
};

const logout = () => {
    Cookies.remove('logedIn');
};

export default {
  login,
  logout,
};
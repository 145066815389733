const actions = {
  POST_LIST_BEGIN: 'POST_LIST_BEGIN',
  POST_LIST_SUCCESS: 'POST_LIST_SUCCESS',
  POST_LIST_ERR: 'POST_LIST_ERR',
  
    postListBegin: () => {
      return {
        type: actions.POST_LIST_BEGIN,
      };
    },
  
    postListSuccess: (data) => {
      return {
        type: actions.POST_LIST_SUCCESS,
        data,
      };
    },
  
    postListErr: (err) => {
      return {
        type: actions.POST_LIST_ERR,
        err,
      };
    },
  
  };
  
  export default actions;
  
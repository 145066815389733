import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Customer = lazy(() => import('../../container/pages/customer'));
const CustomerEdit = lazy(() => import('../../container/pages/CustomerEdit'));

function PagesRoute() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/CustomerEdit`} component={CustomerEdit} />
      <Route path={`${path}`} component={Customer} />
    </Switch>
  );
}

export default PagesRoute;

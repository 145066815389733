const actions = {
    FOLLOW_LIST_BEGIN: 'FOLLOW_LIST_BEGIN',
    FOLLOW_LIST_SUCCESS: 'FOLLOW_LIST_SUCCESS',
    FOLLOW_LIST_ERR: 'FOLLOW_LIST_ERR',
  
    followListBegin: () => {
      return {
        type: actions.FOLLOW_LIST_BEGIN,
      };
    },
  
    followListSuccess: (data) => {
      return {
        type: actions.FOLLOW_LIST_SUCCESS,
        data,
      };
    },
  
    followListErr: (err) => {
      return {
        type: actions.FOLLOW_LIST_ERR,
        err,
      };
    },
  
  };
  
  export default actions;
  
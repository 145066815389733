import React from 'react';
import { Avatar } from 'antd';
import Cookies from 'js-cookie';
import { Link ,NavLink , useRouteMatch, useHistory} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { InfoWraper, UserDropDwon } from './auth-info-style';
import { Popover } from '../../popup/popup';
import { logOut } from '../../../redux/authentication/actionCreator';
import Heading from '../../heading/heading';

function AuthInfo() {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const token = JSON.parse(Cookies.get('logedIn'));

  const SignOut = e => {
    e.preventDefault();
    dispatch(logOut());
  };

  const history = useHistory();
  const selfEfit = () => {
    history.push(`${path}/users/add-user/info?id=${token.id}`);
    window.location.reload();
  }

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <img src={require('../../../static/img/avatar/default_avatar.png')} alt="" />
          <figcaption>
            <Heading as="h5">{token.username}</Heading>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <NavLink to={`${path}/users/add-user/info?id=${token.id}`} onClick={selfEfit}>
              <FeatherIcon icon="settings" /> Settings
            </NavLink>
          </li>
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  return (
    <InfoWraper>
      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            <Avatar src={require('../../../static/img/avatar/default_avatar.png')} />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
}

export default AuthInfo;

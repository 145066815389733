import actions from './actions';
// import initialState from '../../demoData/usersData.json';

const {
  FOLLOW_LIST_BEGIN,
  FOLLOW_LIST_SUCCESS,
  FOLLOW_LIST_ERR,
} = actions;

const initialState = {
  data: null,
  loading: true,
  error: null,
};

const followReducer = (state = initialState, action) => {
  const { type,data,err } = action;
  switch (type) {
    case FOLLOW_LIST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case FOLLOW_LIST_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case FOLLOW_LIST_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

export { followReducer};

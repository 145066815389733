import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Post = lazy(() => import('../../container/pages/post'));
const PostDetails = lazy(() => import('../../container/pages/postDetails'));

function PagesRoute() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/postDetails`} component={PostDetails} />
      <Route path={`${path}/listing`} component={Post} />
    </Switch>
  );
}

export default PagesRoute;
